import React from "react"
import { v4 } from 'uuid'
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { Section, SectionPageTitle, BreadCrumb } from "../components/section"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FadeDrop from "../components/FadeDrop"


const FaqPage = ({ data }) => {
  const pageData = data.allContentfulFaqsReviewsPage.edges[0]
  const faqsData = data.allContentfulFaq
  return(
    <Layout>
      <SEO title={pageData.node.metaTitle} description={pageData.node.metaDescription}/>
      <Section pt="140px" pb="90px" xpt="110px" xpb="60px">       
        <div className="container">
          <BreadCrumb><Link to='/'>Home</Link>/<span>FAQ</span></BreadCrumb>   
          <SectionPageTitle mt="30px" mb="40px" textAlign="center">
            {pageData.node.heroTitle}
          </SectionPageTitle>
          {
            faqsData.edges.map(item=>{
              return(
                <FadeDrop data={item.node} key={v4()} />      
              )
            })
          }
        </div>
      </Section>    
    </Layout>
  )
}

export default FaqPage

export const pageQuery = graphql`
  query FaqPageQuery {
    allContentfulFaqsReviewsPage(filter: {identify: {eq: "Faqs"}}) {
      edges {
        node {
          metaTitle
          metaDescription
          heroTitle
          heroBackground {
            fluid {
              aspectRatio
              base64
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        }
      }
    }
    allContentfulFaq {
      edges {
        node {
          question
          answer {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`

